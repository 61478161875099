<template>
  <div class="form-box">
    <van-form validate-first>
      <van-field
        v-model="form.title"
        label="通知标题"
        name="title"
        label-width="4.2em"
        required
        :rules="[{ required: true }]"
        placeholder="请输入通知标题"
      />

      <van-field
        v-model="form.place"
        label="位置"
        name="place"
        label-width="4.2em"
        required
        :rules="[{ required: true }]"
        placeholder="请输入位置"
      />
      
      <van-field name="imgList" label-width="4.2em" label="图片">
        <template #input>
          <van-uploader
            v-model="imgList"
            @oversize="oversize"
            :beforeDelete="beforeDelete"
            :max-size="5 * 1024 * 1024"
            :max-count="5"
            :afterRead="afterRead"
            :preview-size="80"
          />

        </template>
      </van-field>

      <van-field
        v-model="form.content"
        label="通知内容"
        name="content"
        label-width="4.2em"
        type="textarea"
        rows="5" 
        autosize 
        required
        :rules="[{ required: true }]"
        placeholder="请输入通知内容"
      />

      <van-button class="submit-btn" block type="info" @click="subForm" :loading="subLoading">提交</van-button>
     
    </van-form>
  </div>

</template>

<script>
import { uploadEvent } from '@/api/eventApi'
import axios from '@/utils/axios';
export default {
  name: 'SocialSharing',
  data() {
    return {
      imgList: [],
      subLoading: false,
      form: {
        friendUrl: [],
        category: 11, // 通知类型
        type: 3, // 文章类型
        title: '', // 通知标题
        content: '', // 通知内容
      },
      type: '',
    }
  },
  created() {
    this.form.dealUser = this.$route.query.id
    this.dealName = this.$route.query.name
  },
  mounted() {},
  methods: {
    onConfirm(val) { 
      this.form.dealUser = val.dealUser
      this.form.dealUserName = val.name
      this.showPicker = false
    },
    subForm() {
      if(!this.form.title || !this.form.content) {
        return this.$toast({
            type: 'warning',
            message: '请完善必填项',
            duration: 1000,
          })
      }
      this.subLoading = true
      let arr = this.form.friendUrl
      this.form.friendUrl = ''
      this.form.friendUrl = arr.join(',')
      this.form.openid = this.$store.getters.openId

      uploadEvent(this.form).then(res => {
        this.subLoading = false
        if(res.code == 200) {
          this.form.title = '';
          this.form.content = '';
          this.$toast({
            type: 'success',
            message: '上报成功',
            duration: 1000,
            onClose: () => {
              // this.$router.go(-1)
              window.location.href = '/socialSharingList'
            }
          })
        } else {
          this.$toast({
            type: 'error',
            message: res.msg,
            duration: 1000,
          })
        }

      }).catch(() => {
        this.subLoading = false
      })
    },
    // 图片超出大小
    oversize() {
      this.$toast({
        type: 'warning',
        message: '图片不能超过5M~',
        duration: 500,
      })
    },
    // 删除图片
    beforeDelete(file, detail) {
      this.imgList.splice(detail.index, 1)
      this.form.friendUrl.splice(detail.index, 1)
    },
    // 图片上传后，获取返回的参数，在提交时当做参数传递给后台，uploadImgList是图片上传后返回的图				片信息列表
   afterRead(file) {
      // 上传图
      file.status = 'uploading'
      file.message = '上传中'
      
      let url = file.file
      let formdata = new FormData()
      formdata.append('file', url)

      axios({
        baseURL: '/minio',
        url: '/minio/villageUpload?keepFileName=1&bucketName=village',
        method: 'post',
        data: formdata
      }).then(res => {
        if(res.code == 200) {
          file.url = res.data.url
          this.form.friendUrl.push(res.data.url)
          file.status = 'done'
          file.message = ''
        } else {
          file.status = 'failed'
          file.message = '上传失败'
        }
      }).catch(() => {
          file.status = 'failed'
          file.message = '上传失败'
        })
    },
    // 上传图片接口
    // upload(file) {
    //   return new Promise((resolve, reject) => {
    //     let formdata = new FormData()
    //     formdata.append('file', file)

    //     axios({
    //       baseURL: '/minio',
    //       url: '/minio/villageUpload?keepFileName=1&bucketName=village',
    //       method: 'post',
    //       data: formdata
    //     }).then(res => {
    //       if(res.code == 200) {
    //         resolve(res)
    //       } else {
    //         reject(res)
    //       }
    //     })
        
    //   })
    // },
  },
}
</script>
<style scoped lang="scss">
// @import '@/assets/css/form.css';
::v-deep .row .van-uploader__upload {
  border: 1px solid #eeeeee;
  background-color: #fff;
  border-radius: 5px;
}
</style>

